.orange-btn {
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-top: 20px;
    width: 40vw;
    max-width: 190px;
    height: 40px;
    left: 71px;
    top: 375px;

    text-decoration: none;
    font-size: 0.8;
    background: #FA624A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    border: none;
    cursor: pointer;
}