/* NAV */
.footer-green-gradient {
    background: linear-gradient(to top, #9DB675, white);
}

.footer-yellow-gradient {
    background: linear-gradient(to top, #FFDB94, white);
}

.height {
    height: 12vh;
}