/* NAV */
.flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.title {
    align-self: center; /* Alinha o h1 verticalmente ao centro */
    margin: 0;
}

.botao {
    margin-top: 20px;
    max-width: 90vw;

}

.procura {
    font-size: 1.3rem;
}

