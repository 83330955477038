/* NAV */
.green-gradient {
    background: linear-gradient(to bottom, #9DB675, white);
}

.nav-menu{
    height: 12vh;
    display: flex;
    padding: 15px;
}

.yellow-gradient {
    background: linear-gradient(to bottom, #FFDB94, white);
}

.center{
    align-items: center;
}

.logo-nav{

}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


/* icones */
#logo {
    max-height: 100px;
}

#menu {
    max-height: 25px;
}

