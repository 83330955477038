/* home dev */
.background{
    background: linear-gradient(to bottom, #bee0fa, white);
}

.flex{
    display: flex;
}

.container {
    align-items: center;
    flex-direction: column;
}

.logo {
    width: 400px;
    max-width: 85vw;
}

.manutencao {
    max-width: 90vw;
    text-align: center;
}

.justify-around{
    flex-direction: row;
    justify-content: space-around;
}

@media screen and (min-width: 600px) {
    .maxwidth{
        max-width: 600px;
    }

}
